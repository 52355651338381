import { PUBLIC_SENTRY_ENV } from '$env/static/public';
import { version } from '$lib/../../../../version.json';
import { dsn } from '$lib/constants/sentryConstants';
import { isUserInitiatedBot } from '$lib/helpers/userAgentHelper';
import { handleErrorWithSentry, init } from '@sentry/sveltekit';

init({
    dsn,
    environment: PUBLIC_SENTRY_ENV,
    sampleRate: 1.0,
    release: 'v' + version,
    ignoreErrors: [
        // Email link Microsoft Outlook crawler compatibility error
        // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
        // Browser error when we try to autoplay a video (https://goo.gl/xX8pDD)
        `play() failed because the user didn't interact with the document first`,
        // Error at fetching non existing modules (handled in handleFailedToFetchImportedModuleError)
        'Failed to fetch dynamically imported module'
    ],
    beforeSend(event) {
        const userAgent = event?.request?.headers?.['User-Agent'];
        // Returning null prevents the event from being sent to Sentry
        if (isUserInitiatedBot(userAgent)) {
            return null;
        }

        return event;
    }
});

// This error happens when an user with an old session tries to interact with the app after a new version was deployed.
// This prevents to fetching non existing modules, instead of that we reload the page.
const handleFailedToFetchImportedModuleError = async ({ error }: { error: unknown }) => {
    const message = error instanceof Error ? error.message : String(error);

    if (message.includes('Failed to fetch dynamically imported module')) {
        document.location.reload();
    } else {
        return {
            errorId: crypto.randomUUID(),
            message
        };
    }
};

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry(handleFailedToFetchImportedModuleError);
